<template>
  <div class="home relative">
    <div class="news-bg w-full h-full">
      <a href="./"><img src="@/assets/logo.png" style="margin: 3rem auto 16px auto;"  class="w-2/5" ></a>
      <div class="text-center text-black border-2 w-28 p-1 rounded-2xl border-black put-center">最新消息</div>
      <div class="block bg-white mt-8 mx-auto" style="width: 320px; height:380px; box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;">
        <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FLovssBurger&tabs=timeline&width=443&height=577&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=134098023337098" width="320" height="380" style="border:none;overflow:hidden;margin: 0 auto;" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
      <div class="grid w-full">
        <div class="relative items-center mx-auto my-0">
            <div class="float-left w-12 mx-3"><a href="https://www.facebook.com/LovssBurger" target="_blank" class="fa fa-facebook mr-4"></a></div>
            <div class="float-left w-12 mx-3"><a href="https://www.instagram.com/lovsstogo/" target="_blank" class="fa fa-instagram"></a></div>
            <div class="clear-both"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .fa {
    padding: 8px;
    font-size: 25px;
    width: 45px;
    text-align: center;
    text-decoration: none;
    margin: 15px 2px;
  }
  .fa:hover {
      background-color: #000000;
      color: #ffffff;
  }

  .fa-facebook {
    color: #000000;
    border: 1px solid;
    border-color:#000000;
    border-radius: 50%;
  }
  .fa-instagram {
  color: #000000;
  border: 1px solid;
  border-color:#000000;
  border-radius: 50%;
}
  .put-center {
      margin: 0 auto;
  }
  .home{
    background-image: url('../../assets/news_bg.jpg');
    background-size: cover;
    background-position: top;
    height: 100vh;
    overflow: hidden;
    z-index: 1;
  }
  .ham-brand {
    margin-top: 38px;
    height: 47%;
    width: 80%;
    z-index: 998;
  }
  .brand-text {
    text-align: center;
    font-size: 0.6rem;
  }
</style>

<script>
  export default {
    name: 'mytest',
    data() {
    return {
    display: true
    }
 },
 mounted() {
   
 },
 computed: {},
 methods:{}
 }
</script>
