<template>
  <div class="home relative">
    <img src="../assets/cheese.png" class="absolute" style="bottom: 0; left: 15%; z-index:999 ;" >
    <div class="order-pic-area max-w-md mx-auto pt-56 h-full relative md:max-w-5xl mt-28">
      <div class="h-96 grid grid-cols-4" >
        <div class="h-72"><a :href="this.$props.store_info[0].online_menu_link" target="_blank"><img class="w-10/12 h-auto" :src="this.$props.store_info[0].online_menu_qr_code_image" style="margin: 0 auto;" /></a></div>
        <div class="h-72"><a :href="this.$props.store_info[1].online_menu_link" target="_blank"><img class="w-10/12 h-auto" :src="this.$props.store_info[1].online_menu_qr_code_image" style="margin: 0 auto;" /></a></div>
        <div class="h-72"><a :href="this.$props.store_info[2].online_menu_link" target="_blank"><img class="w-10/12 h-auto" :src="this.$props.store_info[2].online_menu_qr_code_image" style="margin: 0 auto;" /></a></div>
        <div class="h-72"><a :href="this.$props.store_info[3].online_menu_link" target="_blank"><img class="w-10/12 h-auto" :src="this.$props.store_info[3].online_menu_qr_code_image" style="margin: 0 auto;" /></a></div>
        <div class="h-72 hide"><a :href="this.$props.store_info[4].online_menu_link" target="_blank"><img class="w-10/12 h-auto" :src="this.$props.store_info[4].online_menu_qr_code_image" style="margin: 0 auto;" /></a></div>
        <div class="col-span-5 h-24"><img class="pt-8 pb-8" src="../assets/QRcode_note.png" style="margin: 0 auto;" /></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .home{
    background-image: url('../assets/background.jpg');
    background-size: cover;
    background-position: 50% 50%;
    height: 100vh;
    overflow: auto;
    z-index: 1;
  }
  .order-pic-area{
    background-image: url('../assets/order_bg.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 75vh;
  }
  .shop-page-button{
    margin: 22px auto;
    font-size: 30px;
    font-weight: 700;
    border-radius: 30px;
    width: 180px;
    text-align: center;
}
  .ham-brand {
    margin-top: 100px;
    height: 84%;
    width: 92%;
    z-index: 998;
  }
  .hamburger-name-area{
    min-width: 380px;
    width: 380px;
    margin: 0 auto;
    text-align: left;
    color: white;
    border: 3px solid rgba(255, 255, 204);
    min-height: 300px;
    bottom: 0px!important;
    box-shadow: rgba(255, 255, 204, 0.5) 0px 1px 0px, rgba(255, 255, 204, 0.5) 0px 8px 24px, rgba(255, 255, 204, 0.5) 0px 16px 48px;
    animation: flicker 1.5s infinite alternate;
    font-family: 'Noto Sans TC', sans-serif;
    letter-spacing: 0.25rem;
    line-height: 1.5rem;    
  }
  .carousel-open:checked + .carousel-item {
				position: static;
				opacity: 100;
			}
			.carousel-item {
				-webkit-transition: opacity 0.6s ease-out;
				transition: opacity 0.6s ease-out;
			}
			#carousel-1:checked ~ .control-1,
			#carousel-2:checked ~ .control-2,
			#carousel-3:checked ~ .control-3 {
				display: hidden;
			}
			.carousel-indicators {
				list-style: none;
				margin: 0;
				padding: 0;
				position: absolute;
				bottom: -4%;
				left: 0;
				right: 0;
				text-align: center;
				z-index: 10;
			}
			#carousel-1:checked ~ .control-1 ~ .carousel-indicators li:nth-child(1) .carousel-bullet,
			#carousel-2:checked ~ .control-2 ~ .carousel-indicators li:nth-child(2) .carousel-bullet,
			#carousel-3:checked ~ .control-3 ~ .carousel-indicators li:nth-child(3) .carousel-bullet {
				color: #FFFFFF;  /*Set to match the Tailwind colour you want the active one to be */
        opacity: 100;
			}
      .shopDiv {
        display:none;
      }
      .shopDiv.shopOpen {
        display: grid;
      }
</style>


<script>
export default {
  name: 'Order',
  components: {
  },
  props: ['store_info'],
  data () {
    return {
      
    }
  },
  methods: {
    
  },
  mounted(){
    
  }
}
</script>