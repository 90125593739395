<template>
  <div class="home relative">
    <div class="max-w-md mx-auto pl-5 pr-5 h-full overflow-hidden md:max-w-6xl">
      <div class="h-96 grid ontent-end">
        <div class="news-pic-area carousel relative">
          <div class=" news-carousel-inner relative w-full">
            <!--Slide 1-->
            <input class="news-carousel-open" type="radio" id="news-carousel-1" name="carousel" aria-hidden="true" hidden="" checked="checked">
            <div class="news-carousel-item absolute opacity-0 p-6" style="min-height:95vh; margin-top: calc( 16vh - 130px )">
              <div class="block h-full w-full text-white text-5xl text-center">
                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FLovssBurger%2F&tabs=timeline&width=375&height=450&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=134098023337098" width="375" height="450" style="border:none;overflow:hidden; margin:0 auto; transform: scale(1.2)" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
              </div>
            </div>
            <label for="news-carousel-3" class="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
            <label for="news-carousel-2" class="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
            
            <!--Slide 2-->
            <input class="news-carousel-open" type="radio" id="news-carousel-2" name="carousel" aria-hidden="true" hidden="">
            <div class="news-carousel-item absolute opacity-0 p-6" style="min-height:95vh; margin-top: calc( 16vh - 130px )">
              <div class="block h-full w-full text-white text-5xl text-center">
                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FLovssBurger%2F&tabs=timeline&width=375&height=450&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=134098023337098" width="375" height="450" style="border:none;overflow:hidden; margin:0 auto; transform: scale(1.2)" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
              </div>
            </div>
            <label for="news-carousel-1" class="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
            <label for="news-carousel-3" class="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label> 
            
            <!--Slide 3-->
            <input class="news-carousel-open" type="radio" id="news-carousel-3" name="carousel" aria-hidden="true" hidden="">
            <div class="news-carousel-item absolute opacity-0 p-6" style="min-height:95vh; margin-top: calc( 16vh - 130px )">
              <div class="block h-full w-full text-white text-5xl text-center">
                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FLovssBurger%2F&tabs=timeline&width=375&height=450&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=134098023337098" width="375" height="450" style="border:none;overflow:hidden; margin:0 auto; transform: scale(1.2)" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
              </div>
            </div>
            <label for="news-carousel-2" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
            <label for="news-carousel-1" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>

            <!-- Add additional indicators for each slide-->
            <ol class="news-carousel-indicators">
              <li class="inline-block mr-3">
                <label for="news-carousel-1" class="news-carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
              </li>
              <li class="inline-block mr-3">
                <label for="news-carousel-2" class="news-carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
              </li>
              <li class="inline-block mr-3">
                <label for="news-carousel-3" class="news-carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
              </li>
            </ol>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .home{
    background-image: url('../assets/background.jpg');
    background-size: cover;
    background-position: 0% 50%;
    height: 100vh;
    overflow: hidden;
    z-index: 1;
  }
  .news-pic-area{
    background-image: url('../assets/news_background.png');
    background-size: contain;
    background-position: 0% 50%;
    background-repeat: no-repeat;
    height: 100vh;
  }
  .news-page-button{
    margin: 22px auto;
    font-size: 30px;
    font-weight: 700;
    border-radius: 30px;
    width: 180px;
    text-align: center;
}
  .news-brand {
    margin-top: 100px;
    height: 84%;
    width: 92%;
    z-index: 998;
  }
  .news-carousel-open:checked + .news-carousel-item {
				position: static;
				opacity: 100;
			}
			.news-carousel-item {
				-webkit-transition: opacity 0.6s ease-out;
				transition: opacity 0.6s ease-out;
        padding-top: 11rem;
			}
			#news-carousel-1:checked ~ .control-1,
			#news-carousel-2:checked ~ .control-2,
			#news-carousel-3:checked ~ .control-3 {
				display: hidden;
			}
			.news-carousel-indicators {
				list-style: none;
				margin: 0;
				padding: 0;
				position: absolute;
				bottom: -4%;
				left: 0;
				right: 0;
				text-align: center;
				z-index: 10;
			}
			#news-carousel-1:checked ~ .control-1 ~ .news-carousel-indicators li:nth-child(1) .news-carousel-bullet,
			#news-carousel-2:checked ~ .control-2 ~ .news-carousel-indicators li:nth-child(2) .news-carousel-bullet,
			#news-carousel-3:checked ~ .control-3 ~ .news-carousel-indicators li:nth-child(3) .news-carousel-bullet {
				color: #FFFFFF;  /*Set to match the Tailwind colour you want the active one to be */
        opacity: 100;
			}
</style>
