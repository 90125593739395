<template>
  <div class="home relative">
    <div class="ham-brand justify-center m-auto bg-black bg-opacity-75 ">
      <div class="brnad-area bg-contain bg-no-repeat w-full h-full">
        <img src="@/assets/tomato.png" class="absolute" style="bottom:1px; right:1px;" >
        <img src="@/assets/cheese.png" class="absolute" style="bottom:10px; right:50%;" >
        
        <!-- create v-html to insert this.$props.brand_story in raw html -->          
        <div class="brand-text text-white " v-html="this.$props.brand_story" ></div>
        
      </div>
    </div>
  </div>
</template>

<style scoped>
  .home{
    background-image: url('../assets/background.jpg');
    background-size: cover;
    background-position: 50% 50%;
    height: 100vh;
    overflow: hidden;
    z-index: 1;
  }
  .ham-brand {
    margin-top: 100px;
    height: 84%;
    width: 92%;
    z-index: 998;
  }
  .brnad-area {
    background-image: url('../assets/brand_frame.png');
    background-size: 100% auto;
    background-position: center right;
    width: 100%;
    z-index: 999;
  }
  .brand-text {
    text-align: center;
    width: 40%;
    position: absolute;
    left: 50%;
    top: calc( 50% - 2.35em );
    font-size: 10pt;
    font-family: 'Noto Sans TC', sans-serif;
    letter-spacing: 0.25rem;
    line-height: 1.75rem;
  }
</style>

<script>
export default {
  name: 'About',
  props: ['brand_story'],
  data () {
    return {
      msg: ''
    }
  }
}
</script>