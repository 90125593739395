<template>
  <div class="shop relative">
    <div class="max-w-md mx-auto pt-80 pl-5 pr-5 h-full overflow-hidden md:max-w-6xl" style="">
      <!--Banqiao-->
      <div id="shop_banqiao" :class="{ 'shopOpen' : this.$store.state.currentStore === 'banqiao' }" class="shopDiv h-96 grid grid-cols-3 gap-3 content-end"  v-bind="$attrs">
        <div class="col-span-2 pt-6"><img class="" src="../assets/slogan.png" style="margin: 0 auto;" /></div>
        <div class="col-span-3 text-yellow-500 text-lg" style="letter-spacing: 0.35em;">☆☆☆☆☆☆</div>
        <div class="col-span-2 h-68">
          <div class="shop-pic-area carousel relative">
            <div class="carousel-inner relative w-full">
              <!--Slide 1-->
              <input class="carousel-open" type="radio" id="carousel-1" name="carousel" aria-hidden="true" hidden="" checked="checked">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[0].shop_photo1" />
                </div>
              </div>
              <label for="carousel-3" class="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="carousel-2" class="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
              
              <!--Slide 2-->
              <input class="carousel-open" type="radio" id="carousel-2" name="carousel" aria-hidden="true" hidden="">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[0].shop_photo2" />
                </div>
              </div>
              <label for="carousel-1" class="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="carousel-3" class="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label> 
              
              <!--Slide 3-->
              <input class="carousel-open" type="radio" id="carousel-3" name="carousel" aria-hidden="true" hidden="">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[0].shop_photo3" />
                </div>
              </div>
              <label for="carousel-2" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="carousel-1" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>

              <!-- Add additional indicators for each slide-->
              <ol class="carousel-indicators">
                <li class="inline-block mr-3">
                  <label for="carousel-1" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
                <li class="inline-block mr-3">
                  <label for="carousel-2" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
                <li class="inline-block mr-3">
                  <label for="carousel-3" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="h-68">
          <div class="shop-page-button bg-white box-shadow text-black">{{ this.$props.store_info[0].shop_name }}</div>
          <div class="hamburger-name-area bg-black bg-opacity-50 h-2/5 p-8">
            <div class="mt-2 text-white">
              <img class="float-left " src="../assets/phone_icon.png" /><span class="ml-2">{{ this.$props.store_info[0].phone }}</span><div class="clear-both"></div><br>
              <img class="float-left " src="../assets/location_icon.png" /><span class="ml-2">{{ this.$props.store_info[0].address }}</span><div class="clear-both"></div><br>
              <img class="float-left " src="../assets/time_icon.png" /><span style="display: inline-block; margin-left: 5px;" v-html="this.$props.store_info[0].open_time"></span><div class="clear-both"></div>
            </div>
          </div>
        </div>
      </div>
      <!--Xinzhuang-->
      <div id="shop_xinzhuang" :class="{ 'shopOpen' : this.$store.state.currentStore === 'xinzhuang' }" class="shopDiv h-96 grid grid-cols-3 gap-3 content-end"  v-bind="$attrs">
        <div class="col-span-2 pt-6"><img class="" src="../assets/slogan.png" style="margin: 0 auto;" /></div>
        <div class="col-span-3 text-yellow-500 text-lg" style="letter-spacing: 0.35em;">☆☆☆☆☆☆</div>
        <div class="col-span-2 h-68">
          <div class="shop-pic-area carousel relative">
            <div class="carousel-inner relative w-full">
            <!--Slide 1-->
              <input class="carousel-open" type="radio" id="Xinzhuang-carousel-1" name="carousel2" aria-hidden="true" hidden="" checked="checked">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[1].shop_photo1" />
                </div>
              </div>
              <label for="Xinzhuang-carousel-3" class="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="Xinzhuang-carousel-2" class="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
              
              <!--Slide 2-->
              <input class="carousel-open" type="radio" id="Xinzhuang-carousel-2" name="carousel2" aria-hidden="true" hidden="">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[1].shop_photo2" />
                </div>
              </div>
              <label for="Xinzhuang-carousel-1" class="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="Xinzhuang-carousel-3" class="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label> 
              
              <!--Slide 3-->
              <input class="carousel-open" type="radio" id="Xinzhuang-carousel-3" name="carousel2" aria-hidden="true" hidden="">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[1].shop_photo3" />
                </div>
              </div>
              <label for="Xinzhuang-carousel-2" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="Xinzhuang-carousel-1" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>

              <!-- Add additional indicators for each slide-->
              <ol class="carousel-indicators">
                <li class="inline-block mr-3">
                  <label for="Xinzhuang-carousel-1" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
                <li class="inline-block mr-3">
                  <label for="Xinzhuang-carousel-2" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
                <li class="inline-block mr-3">
                  <label for="Xinzhuang-carousel-3" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="h-68">
          <div class="shop-page-button bg-white box-shadow text-black">{{ this.$props.store_info[1].shop_name }}</div>
          <div class="hamburger-name-area bg-black bg-opacity-50 h-2/5 p-8">
            <div class="mt-2 text-white">
              <img class="float-left " src="../assets/phone_icon.png" /><span class="ml-2">{{ this.$props.store_info[1].phone }}</span><div class="clear-both"></div><br>
              <img class="float-left " src="../assets/location_icon.png" /><span class="ml-2">{{ this.$props.store_info[1].address }}</span><div class="clear-both"></div><br>
              <img class="float-left " src="../assets/time_icon.png" /><span style="display: inline-block; margin-left: 5px;" v-html="this.$props.store_info[1].open_time"></span><div class="clear-both"></div>
            </div>
          </div>
        </div>
      </div>
      <!--Jingmei-->
      <div id="shop_jingmei" :class="{ 'shopOpen' : this.$store.state.currentStore === 'jingmei' }" class="shopDiv h-96 grid grid-cols-3 gap-3 content-end"  v-bind="$attrs">
        <div class="col-span-2 pt-6"><img class="" src="../assets/slogan.png" style="margin: 0 auto;" /></div>
        <div class="col-span-3 text-yellow-500 text-lg" style="letter-spacing: 0.35em;">☆☆☆☆☆☆</div>
        <div class="col-span-2 h-68">
          <div class="shop-pic-area carousel relative">
            <div class="carousel-inner relative w-full">
            <!--Slide 1-->
              <input class="carousel-open" type="radio" id="Jingmei-carousel-1" name="carousel3" aria-hidden="true" hidden="" checked="checked">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[2].shop_photo1" />
                </div>
              </div>
              <label for="Jingmei-carousel-3" class="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="Jingmei-carousel-2" class="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
              
              <!--Slide 2-->
              <input class="carousel-open" type="radio" id="Jingmei-carousel-2" name="carousel3" aria-hidden="true" hidden="">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[2].shop_photo2" />
                </div>
              </div>
              <label for="Jingmei-carousel-1" class="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="Jingmei-carousel-3" class="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label> 
              
              <!--Slide 3-->
              <input class="carousel-open" type="radio" id="Jingmei-carousel-3" name="carousel3" aria-hidden="true" hidden="">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[2].shop_photo3" />
                </div>
              </div>
              <label for="Jingmei-carousel-2" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="Jingmei-carousel-4" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>

              <!--Slide 4-->
              <input class="carousel-open" type="radio" id="Jingmei-carousel-4" name="carousel3" aria-hidden="true" hidden="">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[2].shop_photo4" />
                </div>
              </div>
              <label for="Jingmei-carousel-3" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="Jingmei-carousel-5" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
              <!--Slide 5-->
              <input class="carousel-open" type="radio" id="Jingmei-carousel-5" name="carousel3" aria-hidden="true" hidden="">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[2].shop_photo5" />
                </div>
              </div>
              <label for="Jingmei-carousel-4" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="Jingmei-carousel-1" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>

              <!-- Add additional indicators for each slide-->
              <ol class="carousel-indicators">
                <li class="inline-block mr-3">
                  <label for="Jingmei-carousel-1" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
                <li class="inline-block mr-3">
                  <label for="Jingmei-carousel-2" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
                <li class="inline-block mr-3">
                  <label for="Jingmei-carousel-3" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
                <li class="inline-block mr-3">
                  <label for="Jingmei-carousel-4" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
                <li class="inline-block mr-3">
                  <label for="Jingmei-carousel-5" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="h-68">
          <div class="shop-page-button bg-white box-shadow text-black">{{ this.$props.store_info[2].shop_name }}</div>
          <div class="hamburger-name-area bg-black bg-opacity-50 h-2/5 p-8">
            <div class="mt-2 text-white">
              <img class="float-left " src="../assets/phone_icon.png" /><span class="ml-2">{{ this.$props.store_info[2].phone }}</span><div class="clear-both"></div><br>
              <img class="float-left " src="../assets/location_icon.png" /><span class="ml-2">{{ this.$props.store_info[2].address }}</span><div class="clear-both"></div><br>
              <img class="float-left " src="../assets/time_icon.png" /><span style="display: inline-block; margin-left: 5px;" v-html="this.$props.store_info[2].open_time"></span><div class="clear-both"></div>
            </div>
          </div>
        </div>
      </div>
      <!--Sanduo-->
      <div id="shop_sanduo" :class="{ 'shopOpen' : this.$store.state.currentStore === 'sanduo' }" class="shopDiv h-96 grid grid-cols-3 gap-3 content-end"  v-bind="$attrs">
        <div class="col-span-2 pt-6"><img class="" src="../assets/slogan.png" style="margin: 0 auto;" /></div>
        <div class="col-span-3 text-yellow-500 text-lg" style="letter-spacing: 0.35em;">☆☆☆☆☆☆</div>
        <div class="col-span-2 h-68">
          <div class="shop-pic-area carousel relative">
            <div class="carousel-inner relative w-full">
              <!--Slide 1-->
              <input class="carousel-open" type="radio" id="Sanduo-carousel-1" name="carousel4" aria-hidden="true" hidden="" checked="checked">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[3].shop_photo1" />
                </div>
              </div>
              <label for="Sanduo-carousel-3" class="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="Sanduo-carousel-2" class="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
              
              <!--Slide 2-->
              <input class="carousel-open" type="radio" id="Sanduo-carousel-2" name="carousel4" aria-hidden="true" hidden="">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[3].shop_photo2" />
                </div>
              </div>
              <label for="Sanduo-carousel-1" class="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="Sanduo-carousel-3" class="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label> 
              
              <!--Slide 3-->
              <input class="carousel-open" type="radio" id="Sanduo-carousel-3" name="carousel4" aria-hidden="true" hidden="">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[3].shop_photo3" />
                </div>
              </div>
              <label for="Sanduo-carousel-2" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="Sanduo-carousel-1" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>

              <!-- Add additional indicators for each slide-->
              <ol class="carousel-indicators">
                <li class="inline-block mr-3">
                  <label for="Sanduo-carousel-1" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
                <li class="inline-block mr-3">
                  <label for="Sanduo-carousel-2" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
                <li class="inline-block mr-3">
                  <label for="Sanduo-carousel-3" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="h-68">
          <div class="shop-page-button bg-white box-shadow text-black">{{ this.$props.store_info[3].shop_name }}</div>
          <div class="hamburger-name-area bg-black bg-opacity-50 h-2/5 p-8">
            <div class="mt-2 text-white">
              <img class="float-left " src="../assets/phone_icon.png" /><span class="ml-2">{{ this.$props.store_info[3].phone }}</span><div class="clear-both"></div><br>
              <img class="float-left " src="../assets/location_icon.png" /><span class="ml-2">{{ this.$props.store_info[3].address }}</span><div class="clear-both"></div><br>
              <img class="float-left " src="../assets/time_icon.png" /><span style="display: inline-block; margin-left: 5px;" v-html="this.$props.store_info[3].open_time"></span><div class="clear-both"></div>
            </div>
          </div>
        </div>
      </div>
      <!--Neihu-->
      <div id="shop_neihu" :class="{ 'shopOpen' : this.$store.state.currentStore === 'neihu' }" class="shopDiv h-96 grid grid-cols-3 gap-3 content-end"  v-bind="$attrs">
        <div class="col-span-2 pt-6"><img class="" src="../assets/slogan.png" style="margin: 0 auto;" /></div>
        <div class="col-span-3 text-yellow-500 text-lg" style="letter-spacing: 0.35em;">☆☆☆☆☆☆</div>
        <div class="col-span-2 h-68">
          <div class="shop-pic-area carousel relative">
            <div class="carousel-inner relative w-full">
              <!--Slide 1-->
              <input class="carousel-open" type="radio" id="Neihu-carousel-1" name="carousel5" aria-hidden="true" hidden="" checked="checked">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[4].shop_photo1" />
                </div>
              </div>
              <label for="Neihu-carousel-3" class="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="Neihu-carousel-2" class="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
              
              <!--Slide 2-->
              <input class="carousel-open" type="radio" id="Neihu-carousel-2" name="carousel5" aria-hidden="true" hidden="">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[4].shop_photo2" />
                </div>
              </div>
              <label for="Neihu-carousel-1" class="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="Neihu-carousel-3" class="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label> 
              
              <!--Slide 3-->
              <input class="carousel-open" type="radio" id="Neihu-carousel-3" name="carousel5" aria-hidden="true" hidden="">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[4].shop_photo3" />
                </div>
              </div>
              <label for="Neihu-carousel-2" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="Neihu-carousel-4" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>

              <!--Slide 4-->
              <input class="carousel-open" type="radio" id="Neihu-carousel-4" name="carousel5" aria-hidden="true" hidden="">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" src="../assets/shop_Neihu4.jpg" />
                </div>
              </div>
              <label for="Neihu-carousel-3" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="Neihu-carousel-5" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
              <!--Slide 5-->
              <input class="carousel-open" type="radio" id="Neihu-carousel-5" name="carousel5" aria-hidden="true" hidden="">
              <div class="carousel-item absolute opacity-0 p-6" style="min-height:50vh;">
                <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                  <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" src="../assets/shop_Neihu5.jpg" />
                </div>
              </div>
              <label for="Neihu-carousel-4" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
              <label for="Neihu-carousel-1" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>

              <!-- Add additional indicators for each slide-->
              <ol class="carousel-indicators">
                <li class="inline-block mr-3">
                  <label for="Neihu-carousel-1" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
                <li class="inline-block mr-3">
                  <label for="Neihu-carousel-2" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
                <li class="inline-block mr-3">
                  <label for="Neihu-carousel-3" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
                <li class="inline-block mr-3">
                  <label for="Neihu-carousel-4" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
                <li class="inline-block mr-3">
                  <label for="Neihu-carousel-5" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="h-68">
          <div class="shop-page-button bg-white box-shadow text-black">{{ this.$props.store_info[4].shop_name }}</div>
          <div class="hamburger-name-area bg-black bg-opacity-50 h-2/5 p-8">
            <div class="mt-2 text-white">
              <img class="float-left " src="../assets/phone_icon.png" /><span class="ml-2">{{ this.$props.store_info[4].phone }}</span><div class="clear-both"></div><br>
              <img class="float-left " src="../assets/location_icon.png" /><span class="ml-2">{{ this.$props.store_info[4].address }}</span><div class="clear-both"></div><br>
              <img class="float-left " src="../assets/time_icon.png" /><span style="display: inline-block; margin-left: 5px;" v-html="this.$props.store_info[4].open_time"></span><div class="clear-both"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .shop{
    background-image: url('../assets/shop_page_background.jpg');
    background-size: cover;
    background-position: 50% 50%;
    height: 100vh;
    overflow: auto;
    z-index: 1;
  }
  .shop-pic-area{
    background-image: url('../assets/shop_background.jpg');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 414px;
    box-shadow: rgba(255, 255, 204, 0.5) 0px 1px 0px, rgba(255, 255, 204, 0.5) 0px 8px 24px, rgba(255, 255, 204, 0.5) 0px 16px 48px;
  }
  .shop-page-button{
    margin: 22px auto;
    font-size: 30px;
    font-weight: 700;
    border-radius: 30px;
    width: 180px;
    text-align: center;
}
  .ham-brand {
    margin-top: 100px;
    height: 84%;
    width: 92%;
    z-index: 998;
  }
  .hamburger-name-area{
    min-width: 380px;
    width: 380px;
    margin: 0 auto;
    text-align: left;
    color: white;
    border: 3px solid rgba(255, 255, 204);
    min-height: 300px;
    bottom: 0px!important;
    box-shadow: rgba(255, 255, 204, 0.5) 0px 1px 0px, rgba(255, 255, 204, 0.5) 0px 8px 24px, rgba(255, 255, 204, 0.5) 0px 16px 48px;
    animation: flicker 1.5s infinite alternate;
    font-family: 'Noto Sans TC', sans-serif;
    letter-spacing: 0.15rem;
    padding-left: 0.4rem;
    line-height: 1.5rem;    
  }
  .carousel-open:checked + .carousel-item {
				position: static;
				opacity: 100;
			}
			.carousel-item {
				-webkit-transition: opacity 0.6s ease-out;
				transition: opacity 0.6s ease-out;
			}
			#carousel-1:checked ~ .control-1,
			#carousel-2:checked ~ .control-2,
			#carousel-3:checked ~ .control-3 {
				display: hidden;
			}
			.carousel-indicators {
				list-style: none;
				margin: 0;
				padding: 0;
				position: absolute;
				bottom: -4%;
				left: 0;
				right: 0;
				text-align: center;
				z-index: 10;
			}
			#carousel-1:checked ~ .control-1 ~ .carousel-indicators li:nth-child(1) .carousel-bullet,
			#carousel-2:checked ~ .control-2 ~ .carousel-indicators li:nth-child(2) .carousel-bullet,
			#carousel-3:checked ~ .control-3 ~ .carousel-indicators li:nth-child(3) .carousel-bullet {
				color: #FFFFFF;  /*Set to match the Tailwind colour you want the active one to be */
        opacity: 100;
			}
      .shopDiv {
        display:none;
      }
      .shopDiv.shopOpen {
        display: grid;
      }
</style>


<script>
export default {
  name: 'Shop',
  components: {
  },
  props: ['store_info'],
  data () {
    return {
      
    }
  },
  methods: {
    
  },
  mounted(){
    
  }
}
</script>