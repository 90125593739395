<template>
  <div class="home relative">
    <div class="w-full h-full">
        <a href="./"><img src="@/assets/mobile_logo.png" style="margin: 3rem auto 0 auto;"  class="w-1/2" ></a>
        <div class="text-center text-white border-2 w-28 p-1 rounded-2xl put-center">店舖資訊</div>
        <div class="text-center text-white border-2 w-56 p-1 mt-52"  style="margin: 10px auto 0 auto;">#無法一手掌握的現打漢堡</div>
        <!--Banqiao-->
        <div id="shop_banqiao" :class="{ 'shopOpen' : this.$store.state.currentStore === 'banqiao' }"  class="shopDiv ">
          <div class="h-48 slideshow-container">
            <div class="shop-pic-area put-center carousel relative">
              <div class="carousel-inner relative w-full">
                <!--Slide 1-->
                <input class="carousel-open" type="radio" id="carousel-1" name="carousel" aria-hidden="true" hidden="" checked="checked">
                <div class="carousel-item absolute opacity-0 p-2" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline border border-white shadow-md" :src="this.$props.store_info[0].shop_photo1" />
                  </div>
                </div>
                <label for="carousel-3" class="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="carousel-2" class="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
                
                <!--Slide 2-->
                <input class="carousel-open" type="radio" id="carousel-2" name="carousel" aria-hidden="true" hidden="">
                <div class="carousel-item absolute opacity-0 p-2" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[0].shop_photo2" />
                  </div>
                </div>
                <label for="carousel-1" class="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="carousel-3" class="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label> 
                
                <!--Slide 3-->
                <input class="carousel-open" type="radio" id="carousel-3" name="carousel" aria-hidden="true" hidden="">
                <div class="carousel-item absolute opacity-0 p-2" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline" :src="this.$props.store_info[0].shop_photo3" />
                  </div>
                </div>
                <label for="carousel-2" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="carousel-1" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>

                <!-- Add additional indicators for each slide-->
                <ol class="carousel-indicators">
                  <li class="inline-block mr-3">
                    <label for="carousel-1" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                  <li class="inline-block mr-3">
                    <label for="carousel-2" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                  <li class="inline-block mr-3">
                    <label for="carousel-3" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="text-center bg-white text-black border-2 w-56 p-1 mt-52 burger-font"  style="margin: 11% auto 0 auto;">{{ this.$props.store_info[0].shop_name }}</div>
          <div class="flex flex-col w-3/4 mt-6 m-auto text-white burger-font" >
              <div class="flex"><img src="@/assets/phone-icon.png" style="width:20px; height: 20px;" class="mr-3 mt-1" >{{ this.$props.store_info[0].phone }}</div>
              <div class="flex mt-2"><img src="@/assets/map-icon.png" style="width:20px; height: 20px;" class="mr-3 mt-1" >{{ this.$props.store_info[0].address }}</div>
              <div class="flex mt-2">
                  <img src="@/assets/time-icon.png" style="width:20px; height: 20px;" class="mr-3 mt-1" ><span v-html="this.$props.store_info[0].open_time"></span>
              </div>
          </div>
        </div>
        <!--Xinzhuang-->
        <div id="shop_xinzhuang" :class="{ 'shopOpen' : this.$store.state.currentStore === 'xinzhuang' }"  class="shopDiv ">
          <div class="h-48 slideshow-container">
            <div class="shop-pic-area put-center carousel relative">
              <div class="carousel-inner relative w-full">
              <!--Slide 1-->
                <input class="carousel-open" type="radio" id="Xinzhuang-carousel-1" name="carousel2" aria-hidden="true" hidden="" checked="checked">
                <div class="carousel-item absolute opacity-0 p-6" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline border border-white shadow-md" :src="this.$props.store_info[1].shop_photo1" />
                  </div>
                </div>
                <label for="Xinzhuang-carousel-3" class="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="Xinzhuang-carousel-2" class="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
                
                <!--Slide 2-->
                <input class="carousel-open" type="radio" id="Xinzhuang-carousel-2" name="carousel2" aria-hidden="true" hidden="">
                <div class="carousel-item absolute opacity-0 p-6" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline border border-white shadow-md" :src="this.$props.store_info[1].shop_photo2" />
                  </div>
                </div>
                <label for="Xinzhuang-carousel-1" class="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="Xinzhuang-carousel-3" class="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label> 
                
                <!--Slide 3-->
                <input class="carousel-open" type="radio" id="Xinzhuang-carousel-3" name="carousel2" aria-hidden="true" hidden="">
                <div class="carousel-item absolute opacity-0 p-6" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline border border-white shadow-md" :src="this.$props.store_info[1].shop_photo3" />
                  </div>
                </div>
                <label for="Xinzhuang-carousel-2" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="Xinzhuang-carousel-1" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>

                <!-- Add additional indicators for each slide-->
                <ol class="carousel-indicators">
                  <li class="inline-block mr-3">
                    <label for="Xinzhuang-carousel-1" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                  <li class="inline-block mr-3">
                    <label for="Xinzhuang-carousel-2" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                  <li class="inline-block mr-3">
                    <label for="Xinzhuang-carousel-3" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="text-center bg-white text-black border-2 w-56 p-1 mt-52 burger-font"  style="margin: 11% auto 0 auto;">{{ this.$props.store_info[1].shop_name }}</div>
          <div class="flex flex-col w-3/4 mt-6 m-auto text-white burger-font" >
              <div class="flex"><img src="@/assets/phone-icon.png" style="width:20px; height: 20px;" class="mr-3 mt-1" >{{ this.$props.store_info[1].phone }}</div>
              <div class="flex mt-2"><img src="@/assets/map-icon.png" style="width:20px; height: 20px;" class="mr-3 mt-1" >{{ this.$props.store_info[1].address }}</div>
              <div class="flex mt-2">
                  <img src="@/assets/time-icon.png" style="width:20px; height: 20px;" class="mr-3 mt-1" ><span v-html="this.$props.store_info[1].open_time"></span>
              </div>
          </div>
        </div>
        <!--Jingmei-->
        <div id="shop_jingmei" :class="{ 'shopOpen' : this.$store.state.currentStore === 'jingmei' }"  class="shopDiv ">
          <div class="h-48 slideshow-container">
            <div class="shop-pic-area put-center carousel relative">
              <div class="carousel-inner relative w-full">
              <!--Slide 1-->
                <input class="carousel-open" type="radio" id="Jingmei-carousel-1" name="carousel3" aria-hidden="true" hidden="" checked="checked">
                <div class="carousel-item absolute opacity-0 p-6" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline border border-white shadow-md" :src="this.$props.store_info[2].shop_photo1" />
                  </div>
                </div>
                <label for="Jingmei-carousel-3" class="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="Jingmei-carousel-2" class="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
                
                <!--Slide 2-->
                <input class="carousel-open" type="radio" id="Jingmei-carousel-2" name="carousel3" aria-hidden="true" hidden="">
                <div class="carousel-item absolute opacity-0 p-6" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline border border-white shadow-md" :src="this.$props.store_info[2].shop_photo2" />
                  </div>
                </div>
                <label for="Jingmei-carousel-1" class="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="Jingmei-carousel-3" class="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label> 
                
                <!--Slide 3-->
                <input class="carousel-open" type="radio" id="Jingmei-carousel-3" name="carousel3" aria-hidden="true" hidden="">
                <div class="carousel-item absolute opacity-0 p-6" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline border border-white shadow-md" :src="this.$props.store_info[2].shop_photo3" />
                  </div>
                </div>
                <label for="Jingmei-carousel-2" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="Jingmei-carousel-4" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>

                <!--Slide 4-->
                <input class="carousel-open" type="radio" id="Jingmei-carousel-4" name="carousel3" aria-hidden="true" hidden="">
                <div class="carousel-item absolute opacity-0 p-6" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline border border-white shadow-md" :src="this.$props.store_info[2].shop_photo4" />
                  </div>
                </div>
                <label for="Jingmei-carousel-3" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="Jingmei-carousel-5" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
                <!--Slide 5-->
                <input class="carousel-open" type="radio" id="Jingmei-carousel-5" name="carousel3" aria-hidden="true" hidden="">
                <div class="carousel-item absolute opacity-0 p-6" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline border border-white shadow-md" :src="this.$props.store_info[2].shop_photo5" />
                  </div>
                </div>
                <label for="Jingmei-carousel-4" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="Jingmei-carousel-1" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>

                <!-- Add additional indicators for each slide-->
                <ol class="carousel-indicators">
                  <li class="inline-block mr-3">
                    <label for="Jingmei-carousel-1" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                  <li class="inline-block mr-3">
                    <label for="Jingmei-carousel-2" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                  <li class="inline-block mr-3">
                    <label for="Jingmei-carousel-3" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                  <li class="inline-block mr-3">
                    <label for="Jingmei-carousel-4" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                  <li class="inline-block mr-3">
                    <label for="Jingmei-carousel-5" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="text-center bg-white text-black border-2 w-56 p-1 mt-52 burger-font"  style="margin: 11% auto 0 auto;">{{ this.$props.store_info[2].shop_name }}</div>
          <div class="flex flex-col w-3/4 mt-6 m-auto text-white burger-font" >
              <div class="flex"><img src="@/assets/phone-icon.png" style="width:20px; height: 20px;" class="mr-3 mt-1" >{{ this.$props.store_info[2].phone }}</div>
              <div class="flex mt-2"><img src="@/assets/map-icon.png" style="width:20px; height: 20px;" class="mr-3 mt-1" >{{ this.$props.store_info[2].address }}</div>
              <div class="flex mt-2">
                  <img src="@/assets/time-icon.png" style="width:20px; height: 20px;" class="mr-3 mt-1" ><span v-html="this.$props.store_info[2].open_time"></span>
              </div>
          </div>
        </div>
        <!--Sanduo-->
        <div id="shop_sanduo" :class="{ 'shopOpen' : this.$store.state.currentStore === 'sanduo' }"  class="shopDiv ">
          <div class="h-48 slideshow-container">
            <div class="shop-pic-area put-center carousel relative">
              <div class="carousel-inner relative w-full">
                <!--Slide 1-->
                <input class="carousel-open" type="radio" id="Sanduo-carousel-1" name="carousel4" aria-hidden="true" hidden="" checked="checked">
                <div class="carousel-item absolute opacity-0 p-6" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline border border-white shadow-md" :src="this.$props.store_info[3].shop_photo1" />
                  </div>
                </div>
                <label for="Sanduo-carousel-3" class="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="Sanduo-carousel-2" class="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
                
                <!--Slide 2-->
                <input class="carousel-open" type="radio" id="Sanduo-carousel-2" name="carousel4" aria-hidden="true" hidden="">
                <div class="carousel-item absolute opacity-0 p-6" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline border border-white shadow-md" :src="this.$props.store_info[3].shop_photo2" />
                  </div>
                </div>
                <label for="Sanduo-carousel-1" class="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="Sanduo-carousel-3" class="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label> 
                
                <!--Slide 3-->
                <input class="carousel-open" type="radio" id="Sanduo-carousel-3" name="carousel4" aria-hidden="true" hidden="">
                <div class="carousel-item absolute opacity-0 p-6" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline border border-white shadow-md" :src="this.$props.store_info[3].shop_photo3" />
                  </div>
                </div>
                <label for="Sanduo-carousel-2" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="Sanduo-carousel-1" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>

                <!-- Add additional indicators for each slide-->
                <ol class="carousel-indicators">
                  <li class="inline-block mr-3">
                    <label for="Sanduo-carousel-1" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                  <li class="inline-block mr-3">
                    <label for="Sanduo-carousel-2" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                  <li class="inline-block mr-3">
                    <label for="Sanduo-carousel-3" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="text-center bg-white text-black border-2 w-56 p-1 mt-52 burger-font"  style="margin: 11% auto 0 auto;">{{ this.$props.store_info[3].shop_name }}</div>
          <div class="flex flex-col w-3/4 mt-6 m-auto text-white burger-font" >
              <div class="flex"><img src="@/assets/phone-icon.png" style="width:20px; height: 20px;" class="mr-3 mt-1" >{{ this.$props.store_info[3].phone }}</div>
              <div class="flex mt-2"><img src="@/assets/map-icon.png" style="width:20px; height: 20px;" class="mr-3 mt-1" >{{ this.$props.store_info[3].address }}</div>
              <div class="flex mt-2">
                  <img src="@/assets/time-icon.png" style="width:20px; height: 20px;" class="mr-3 mt-1" ><span v-html="this.$props.store_info[3].open_time"></span>
              </div>
          </div>
        </div>
        <!--Neihu-->
        <div id="shop_neihu" :class="{ 'shopOpen' : this.$store.state.currentStore === 'neihu' }"  class="shopDiv ">
          <div class="h-48 slideshow-container">
            <div class="shop-pic-area carousel relative">
              <div class="carousel-inner relative w-full">
                <!--Slide 1-->
                <input class="carousel-open" type="radio" id="Neihu-carousel-1" name="carousel5" aria-hidden="true" hidden="" checked="checked">
                <div class="carousel-item absolute opacity-0 p-6" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline border border-white shadow-md" :src="this.$props.store_info[4].shop_photo1" />
                  </div>
                </div>
                <label for="Neihu-carousel-3" class="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="Neihu-carousel-2" class="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
                
                <!--Slide 2-->
                <input class="carousel-open" type="radio" id="Neihu-carousel-2" name="carousel5" aria-hidden="true" hidden="">
                <div class="carousel-item absolute opacity-0 p-6" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline border border-white shadow-md" :src="this.$props.store_info[4].shop_photo2" />
                  </div>
                </div>
                <label for="Neihu-carousel-1" class="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="Neihu-carousel-3" class="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label> 
                
                <!--Slide 3-->
                <input class="carousel-open" type="radio" id="Neihu-carousel-3" name="carousel5" aria-hidden="true" hidden="">
                <div class="carousel-item absolute opacity-0 p-6" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline border border-white shadow-md" :src="this.$props.store_info[4].shop_photo3" />
                  </div>
                </div>
                <label for="Neihu-carousel-2" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="Neihu-carousel-4" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>

                <!--Slide 4-->
                <input class="carousel-open" type="radio" id="Neihu-carousel-4" name="carousel5" aria-hidden="true" hidden="">
                <div class="carousel-item absolute opacity-0 p-6" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline border border-white shadow-md" :src="this.$props.store_info[4].shop_photo4" />
                  </div>
                </div>
                <label for="Neihu-carousel-3" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="Neihu-carousel-5" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
                <!--Slide 5-->
                <input class="carousel-open" type="radio" id="Neihu-carousel-5" name="carousel5" aria-hidden="true" hidden="">
                <div class="carousel-item absolute opacity-0 p-6" style="min-height:27vh;">
                  <div class="block h-full w-full mt-4 rounded-3xl text-white text-5xl text-center">
                    <img class="rounded-3xl w-auto h-auto w-11/12 text-center inline border border-white shadow-md" :src="this.$props.store_info[4].shop_photo5" />
                  </div>
                </div>
                <label for="Neihu-carousel-4" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                <label for="Neihu-carousel-1" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>

                <!-- Add additional indicators for each slide-->
                <ol class="carousel-indicators">
                  <li class="inline-block mr-3">
                    <label for="Neihu-carousel-1" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                  <li class="inline-block mr-3">
                    <label for="Neihu-carousel-2" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                  <li class="inline-block mr-3">
                    <label for="Neihu-carousel-3" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                  <li class="inline-block mr-3">
                    <label for="Neihu-carousel-4" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                  <li class="inline-block mr-3">
                    <label for="Neihu-carousel-5" class="carousel-bullet cursor-pointer block text-4xl text-white opacity-50 hover:opacity-100 ">•</label>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="text-center bg-white text-black border-2 w-56 p-1 mt-52 burger-font"  style="margin: 11% auto 0 auto;">{{ this.$props.store_info[4].shop_name }}</div>
          <div class="flex flex-col w-3/4 mt-6 m-auto text-white burger-font" >
              <div class="flex"><img src="@/assets/phone-icon.png" style="width:20px; height: 20px;" class="mr-3 mt-1" >{{ this.$props.store_info[4].phone }}</div>
              <div class="flex mt-2"><img src="@/assets/map-icon.png" style="width:20px; height: 20px;" class="mr-3 mt-1" >{{ this.$props.store_info[4].address }}</div>
              <div class="flex mt-2">
                  <img src="@/assets/time-icon.png" style="width:20px; height: 20px;" class="mr-3 mt-1" ><span v-html="this.$props.store_info[4].open_time"></span>
              </div>
          </div>
        </div>
        <img src="@/assets/bottom_sign.png" class="absolute" style="bottom:1%; right:38%;" >
    </div>
  </div>
</template>

<style scoped>
  .put-center {
      margin: 0 auto;
  }
  .home{
    background-image: url('../../assets/shop_mobile_bg.jpg');
    background-size: cover;
    background-position: 50% 50%;
    height: 100vh;
    overflow: hidden;
    z-index: 1;
  }
  .shop-pic-area{
  width: 88%;
  }

.mySlides {display: none;}
img {vertical-align: middle;}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: 1.25rem;
  background-image: url('../../assets/shop_background_mobile.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: rgba(255, 255, 204, 0.5) 0px 1px 0px, rgba(255, 255, 204, 0.5) 0px 8px 24px, rgba(255, 255, 204, 0.5) 0px 16px 48px;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  height: 8px;
  width: 8px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 5.6s ease;
}

.active {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 5.5s;
  animation-name: fade;
  animation-duration: 5.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .text {font-size: 11px}
}

  .carousel-open:checked + .carousel-item {
				position: static;
				opacity: 100;
			}
			.carousel-item {
				-webkit-transition: opacity 0.6s ease-out;
				transition: opacity 0.6s ease-out;
			}
			#carousel-1:checked ~ .control-1,
			#carousel-2:checked ~ .control-2,
			#carousel-3:checked ~ .control-3 {
				display: hidden;
			}
			.carousel-indicators {
				list-style: none;
				margin: 0;
				padding: 0;
				position: absolute;
				bottom: -4%;
				left: 0;
				right: 0;
				text-align: center;
				z-index: 10;
			}
			#carousel-1:checked ~ .control-1 ~ .carousel-indicators li:nth-child(1) .carousel-bullet,
			#carousel-2:checked ~ .control-2 ~ .carousel-indicators li:nth-child(2) .carousel-bullet,
			#carousel-3:checked ~ .control-3 ~ .carousel-indicators li:nth-child(3) .carousel-bullet {
				color: #FFFFFF;  /*Set to match the Tailwind colour you want the active one to be */
        opacity: 100;
			}
      .shopDiv {
        display:none;
      }
      .shopDiv.shopOpen {
        display: block;
      }
</style>


<script>
export default {
    name: 'shop',
    data(){
        return {
            slideIndex: 0,
        }
    },
    props: ['store_info'],
    methods: {
        
        showSlides: function() {
            var i;
            var slides = document.getElementsByClassName("mySlides");
            var dots = document.getElementsByClassName("dot");
            
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";  
            }
            this.$data.slideIndex++;

            if ( this.$data.slideIndex > slides.length) {this.$data.slideIndex = 1}    
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" active", "");
            }
            slides[this.$data.slideIndex-1].style.display = "block";  
            dots[this.$data.slideIndex-1].className += " active";
            setTimeout(this.showSlides, 100000); // Change image every 2 seconds

        }
    },
    mounted() {
        //this.showSlides();
        this.$store.state.currentStore = 'banqiao'
    }
}
</script>