<template>
  <div class="home relative">
    <div class="about-bg w-full h-full overflow-hidden">
        <a href="./"><img src="@/assets/mobile_logo.png" style="margin: 3rem auto 0 auto;"  class="w-1/2" ></a>
        <div class="text-center text-white border-2 w-28 p-1 rounded-2xl put-center">品牌故事</div>
          <div class="relative ham-brand justify-center m-auto bg-black">
                <img src="@/assets/small-garlic.png" class="absolute" style="top: -10%; right:-5%;" >
                <img src="@/assets/small-steak.png" class="absolute" style="top:35%; right:-20%;" >
                <img src="@/assets/small-hamburger.png" class="absolute bottom-0" style="right:40%;" >
                <img src="@/assets/small-tomato.png" class="absolute" style="bottom: -10%; left:-5%;" >
                <img src="@/assets/small-fries.png" class="absolute" style="bottom: -10%; right:-5%;" >
              <img src="@/assets/about_lovssburger.png" class="put-center w-10/12 pt-10" >
              <img src="@/assets/about_lovssburger_line.png" class="put-center w-10/12 pt-6" >
                  <div class="brand-text text-white leading-6 tracking-tight pt-6 pl-2 pr-2" v-html="this.$props.brand_story" >
                  
                  </div>
              <img src="@/assets/about_lovssburger_line.png" class="put-center w-10/12 pt-12 pb-3" >
          </div>
        <img src="@/assets/best_in_town.png" style="margin: 3rem auto 3rem auto;" >
        <img src="@/assets/bottom_sign.png" class="absolute" style="bottom:1%; right:38%;" >
    </div>
  </div>
</template>

<style scoped>
  .put-center {
      margin: 0 auto;
  }
  .home{
    background-image: url('../../assets/mobile_bg.jpg');
    background-size: cover;
    background-position: 50% 50%;
    height: 100vh;
    overflow: hidden;
    z-index: 1;
  }
  .about-bg {
    background-image: url('../../assets/about_bg.png');
    background-position: 15px 150px;
    background-repeat: no-repeat;
  }
  .ham-brand {
    margin-top: 38px;
    width: 80%;
    z-index: 998;
  }
  .brand-text {
    text-align: center;
    font-size: 10pt;
    font-family: 'Noto Sans TC', sans-serif;
  }
</style>

<script>
export default {
  name: 'About',
  props: ['brand_story'],
  data () {
    return {
      msg: ''
    }
  }
}
</script>