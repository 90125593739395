<template>
  <div class="home relative">
    <div class="w-full h-full pt-8">
        <div class="order-container p-4">
          <a :href="this.$props.store_info[0].online_menu_link" target="_blank"><img class="put-center pt-28" :src="this.$props.store_info[0].online_menu_qr_code_image"  /></a>
          <a :href="this.$props.store_info[1].online_menu_link" target="_blank"><img class="put-center" :src="this.$props.store_info[1].online_menu_qr_code_image"  /></a>
          <a :href="this.$props.store_info[2].online_menu_link" target="_blank"><img class="put-center" :src="this.$props.store_info[2].online_menu_qr_code_image"  /></a>
          <a :href="this.$props.store_info[3].online_menu_link" target="_blank"><img class="put-center" :src="this.$props.store_info[3].online_menu_qr_code_image"  /></a>
          <a class="hide" :href="this.$props.store_info[4].online_menu_link" target="_blank"><img class="put-center" :src="this.$props.store_info[4].online_menu_qr_code_image"  /></a>
          <a href="./"><img src="@/assets/mobile_order_message.png" class="w-2/3 put-center pt-4" /></a>
        </div>
    </div>
  </div>
</template>

<style scoped>
  .put-center {
      margin: 0 auto;
  }
  .home{
    background-image: url('../../assets/mobile_bg.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    overflow: hidden;
    z-index: 1;
  }
.mySlides {display: none;}
img {vertical-align: middle;}

.order-container {
    background-image: url('../../assets/mobile_order_bg.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 1rem;
    height: 100vh;
    overflow: hidden;
    z-index: 5;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  height: 8px;
  width: 8px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 5.6s ease;
}

.active {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 5.5s;
  animation-name: fade;
  animation-duration: 5.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .text {font-size: 11px}
}

  .carousel-open:checked + .carousel-item {
				position: static;
				opacity: 100;
			}
			.carousel-item {
				-webkit-transition: opacity 0.6s ease-out;
				transition: opacity 0.6s ease-out;
			}
			#carousel-1:checked ~ .control-1,
			#carousel-2:checked ~ .control-2,
			#carousel-3:checked ~ .control-3 {
				display: hidden;
			}
			.carousel-indicators {
				list-style: none;
				margin: 0;
				padding: 0;
				position: absolute;
				bottom: -4%;
				left: 0;
				right: 0;
				text-align: center;
				z-index: 10;
			}
			#carousel-1:checked ~ .control-1 ~ .carousel-indicators li:nth-child(1) .carousel-bullet,
			#carousel-2:checked ~ .control-2 ~ .carousel-indicators li:nth-child(2) .carousel-bullet,
			#carousel-3:checked ~ .control-3 ~ .carousel-indicators li:nth-child(3) .carousel-bullet {
				color: #FFFFFF;  /*Set to match the Tailwind colour you want the active one to be */
        opacity: 100;
			}
      .shopDiv {
        display:none;
      }
      .shopDiv.shopOpen {
        display: block;
      }
</style>


<script>
export default {
    name: 'Order',
    data(){
        return {
            slideIndex: 0,
        }
    },
    props: ['store_info'],
    methods: {
        
        showSlides: function() {
            var i;
            var slides = document.getElementsByClassName("mySlides");
            var dots = document.getElementsByClassName("dot");
            
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";  
            }
            this.$data.slideIndex++;

            if ( this.$data.slideIndex > slides.length) {this.$data.slideIndex = 1}    
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" active", "");
            }
            slides[this.$data.slideIndex-1].style.display = "block";  
            dots[this.$data.slideIndex-1].className += " active";
            setTimeout(this.showSlides, 100000); // Change image every 2 seconds

        }
    },
    mounted() {
        //this.showSlides();
    }
}
</script>